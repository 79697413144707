<template>
  <section>
    <article class="panel">
      <div class="panel-heading has-text-centered">
        {{ label }}
      </div>
      <div class="panel-block">
        <b-table :data="interno.items" class="table" striped hoverable>
          <template >
            <b-table-column v-slot="props" field="" label="">
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="delete"
                class="button-delete"
                @click="deleteItem(props.row)"
              >
              </b-button>
            </b-table-column>
            <b-table-column width="10%" v-slot="props" field="apelido" :label="$t('BUSCAITENSDESPESAS.APELIDO')">
              <template expanded>
                {{ props.row.apelido }}
              </template>
            </b-table-column>
            <b-table-column v-slot="props" field="nome" :label="$t('BUSCAITENSDESPESAS.NOME')">
              <b-autocomplete
                :data="data"
                v-model="props.row.nome"
                ref="autocomplete"
                field="nome"
                icon="magnify"
                :loading="isFetching"
                @typing="getAsyncData"
                @select="selectItem(props.row, $event)"
              >
                <template slot="empty">
                  Nenhum {{ label }} encontrado
                </template>
                <template slot-scope="props">
                  <div>
                    <div class="">
                      <div class="tag">{{ props.option.id }}</div>
                      {{ props.option.apelido }} {{ props.option.nome }}
                    </div>
                    <div><b> - {{$t('BUSCAITENSDESPESAS.APELIDO')}}:</b> {{ props.option.apelido }}</div>
                    <div><b> - {{$t('BUSCAITENSDESPESAS.SETOR')}}:</b> {{ props.option.setorNome }}</div>
                    <div>
                      <b> - {{$t('BUSCAITENSDESPESAS.RECIPIENTES')}}:</b> {{ props.option.recipienteId }}
                    </div>
                    <div>
                      <b> - {{$t('BUSCAITENSDESPESAS.MATERIAL')}}:</b> {{ props.option.materialNome }}
                    </div>
                    <div>
                      <b> - {{$t('BUSCAITENSDESPESAS.CONSERVANTE')}}:</b> {{ props.option.conservanteNome }}
                    </div>
                  </div>
                  <slot
                    v-if="hasDefaultSlot"
                    :option="props.option"
                    :index="props.index"
                  />
                </template>
              </b-autocomplete>
            </b-table-column>
            <b-table-column width="20%" v-slot="props" field="unidadeDeMedida" :label="$t('BUSCAITENSDESPESAS.UNIDADEDEMEDIDA')">
              <b-select v-model="props.row.unidadeDeMedida" expanded>
                  <option v-for="(unidade, index) in unidadesDeMedida" :key="index" :value="unidade">
                    <span>{{ unidade.codigo }}</span>
                    {{ unidade.termo }} - {{ unidade.descricao }}
                  </option>
              </b-select>
            </b-table-column>
            <b-table-column width="5%" v-slot="props" field="quantidade" :label="$t('BUSCAITENSDESPESAS.QUANTIDADE')">
              <b-input v-model="props.row.quantidade" type="number" step="0.01" expanded></b-input>
            </b-table-column>
            <b-table-column width="25%" v-slot="props" field="codigoTabela" :label="$t('BUSCAITENSDESPESAS.CODIGODATABELA')">
              <b-select v-model="props.row.codigoTabela" expanded>
                  <option>00 - Tabela própria das operadoras</option>
                  <option>18 - Diárias, taxas e gases medicinais</option>
                  <option>19 - Materiais e Órteses, Próteses e Materiais Especiais (OPME)</option>
                  <option>20 - Medicamentos</option>
                  <option>22 - Procedimentos e eventos em saúde</option>
                  <option>90 - Tabela Própria Pacote Odontológico</option>
                  <option>98 - Tabela Própria de Pacotes</option>
              </b-select>
            </b-table-column>
          </template>
        </b-table>
        <div class="buttons has-text-centered">
          <b-button
            type="is-success"
            native-type="button"
            icon-left="plus"
            class="center"
            @click="addItem()"
          >
            {{$t('BUSCAITENSDESPESAS.INCLUIR')}}
          </b-button>
        </div>
      </div>
    </article>
  </section>
</template>

<style scoped>
.button-delete {
  max-height: 1.5rem;
}

.table {
  width: 100%;
  display: block;
  /* max-height: 30vh;
  overflow-y: auto; */
}

.panel-block {
  display: block;
}

.center {
  margin: 0 auto;
}
</style>

<script>
import debounce from "lodash/debounce";

export default {
  props: {
    items: [],
    label: String,
  },
  data() {
    return {
      data: [],
      inputid: this.id,
      isFetching: false,
      table: "ExameRecipiente",
      interno: {
        items: [],
      },
      unidadesDeMedida: [],
    };
  },
  watch: {
    items() {
      this.interno.items = this.items;
    },
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
  },
  mounted() {
    this.getUnidadeDeMedida();
    this.interno.items = this.items;
  },
  methods: {
    getAsyncData: debounce(function (name) {
      if (!name.length) {
        this.data = [];
        return;
      }
      this.isFetching = true;
      this.$http
        .get(`/Search/${this.table}?texto=${name}&outrasDespesas=${true}&_=${new Date().getTime()}`)
        .then(({ data }) => {
          this.data = [];
          data.forEach((item) => this.data.push(item));
        })
        .catch((error) => {
          this.data = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
    getUnidadeDeMedida() {
      this.isFetching = true;
      this.$http.get(`/api/search/UnidadeDeMedidaItensDespesas`)
        .then(({ data }) => {
          this.unidadesDeMedida = data;
        })
        .catch((error) => {
          this.unidadesDeMedida = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    addItem() {
      this.interno.items.push({
        id: null,
        apelido: null,
        changed: true,
        show: true,
      });
      setTimeout(() => {
        this.$refs.autocomplete.focus();
      }, 100);
    },
    deleteItem(item) {
      item.show = false;
      item.id = `${item.id}_0`;
      this.interno.items = this.interno.items.filter((i) => i.id !== item.id);
      this.change();
    },
    selectItem(item, option) {
      item.id = option.id;
      item.apelido = option.apelido;
      item.materialNome = option.materialNome;
      item.conservanteNome = option.conservanteNome;
      item.recipienteId = option.recipienteId;
      item.changed = true;
      this.change();
    },
    change() {
      const result = this.interno.items
        .map((i) => {
          const id =
            i.changed && i.id?.toString()?.indexOf("_") < 0
              ? i.id + "_" + i.recipienteId
              : i.id;
          return {
            ...i,
            id: id,
          };
        });

      this.$emit("update:items", result);
    },
  },
};
</script>
