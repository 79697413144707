<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>

        <b-modal :active.sync="isModalImpressao" has-modal-card trap-focus aria-role="dialog" aria-modal>
            <modalImpressaoAgendamento :id="imprimir.id" :modelos="model.comprovanteModelos" @imprimir="imprimirModelo"></modalImpressaoAgendamento>
        </b-modal>

        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    
                    <div class="columns is-multiline is-mobile is-gapless">                        
                            <div  v-show="( config.agendaExibeSeletorEquipamento )" class="column is-2">
                                    <label class="label" style="visibility: hidden;">Label Filtro</label>
                                    <b-tooltip :label="$t('AGENDA.MENSAGEMAGENDALABORATORIAL')" multilined>
                                        <b-switch v-model="agendasLaboratorial" @input="agendasImagem=false; equipamentosSelecionados=[]; loadTiposAgendamentos();" native-value="false">                                        
                                            {{$t('AGENDA.AGENDASLABORATORIAL')}}
                                        </b-switch>                        
                                    </b-tooltip>
                            </div>
                            <div  v-show="( config.agendaExibeSeletorEquipamento )" class="column is-2">
                                    <label class="label" style="visibility: hidden;">Label Filtro</label>
                                    <b-tooltip :label="$t('AGENDA.MENSAGEMAGENDAIMAGEM')" multilined>
                                        <b-switch v-model="agendasImagem" @input="agendasLaboratorial=false; equipamentosSelecionados=[]; loadTiposAgendamentos();" native-value="false">                                        
                                            {{$t('AGENDA.AGENDASIMAGEM')}}
                                        </b-switch>                        
                                    </b-tooltip>
                            </div>                        
                            <div  v-show="( config.agendaExibeSeletorEquipamento )" class="column is-12 mt-3">
                                <nav class="panel">
                                    <p class="panel-heading">
                                        {{$t('AGENDA.EQUIPAMENTOS')}}
                                        <button v-show="listaEquipamentos && listaEquipamentos.length>0"  type="button" class="delete is-pulled-right" 
                                            aria-label="delete" @click="listaEquipamentos=[]; procurarEquipamentoTexto=null;"></button>
                                    </p>
                                    <div class="panel-block" v-show="equipamentosSelecionados && equipamentosSelecionados.length>0">
                                        <b-taginput
                                            v-model="equipamentosSelecionados"                                            
                                            autocomplete
                                            :allow-new="false"                                                                                                                                                      
                                            field="nome"
                                            icon="label" >                                                          
                                        </b-taginput>                                        
                                    </div>
                                    <div class="panel-block">
                                        <b-input v-model="procurarEquipamentoTexto"
                                                    icon="magnify"
                                                    @input="inputListaEquipamentos"
                                                    @keydown.native.esc="listaEquipamentos=[]; procurarEquipamentoTexto=null;"
                                                    @keydown.native.down="incluindoEquipamentoIndex++"
                                                    @keydown.native.up="incluindoEquipamentoIndex--"
                                                    @keydown.native.enter.prevent="incluirEquipamento(listaEquipamentos[incluindoEquipamentoIndex])"
                                                    placeholder="Pesquisar Equipamentos"
                                                    ref="procurarEquipamentoTextoInput"></b-input>

                                    </div>                                    
                                    <template v-for="(equip, index) in listaEquipamentos" >
                                        <template >
                                         
                                            <a class="panel-block columns is-multiline" :key="index" @click="incluirEquipamento(equip)" v-bind:class="{ 'has-background-primary': incluindoEquipamentoIndex == index }">
                                                <div class="column is-2">
                                                    <h4 class="is-size-5 has-text-weight-bold">
                                                        {{ equip.nome }}
                                                    </h4>
                                                </div>
                                                <div class="column is-6">
                                                    <p>{{ equip.nome }}</p>
                                                    <small class="is-italic" >
                                                        {{ equip.version }}
                                                    </small>
                                                </div>

                                            </a>
                                        </template>
                                    </template>
                                </nav>
                            </div>                                                 
                        <div v-show="( config.agendaPermitirFiltrarTodos && !config.ocultarCalendarioMensal && !config.agendaExibeSeletorEquipamento  )" class="column is-2-desktop is-half-mobile">
                            <b-field>
                                <b-radio-button v-model="model.tipoAgendamentoIdFiltro" @input="loadData()" :native-value="0" >
                                    <span >Todos</span>
                                </b-radio-button>
                            </b-field>
                        </div>
                        <template v-for="(tipo) in model.tiposAgendamentoAtivos">
                            <div class="column is-2-desktop is-half-mobile"  v-bind:key="tipo.id">
                                <b-field>
                                    <b-radio-button  @input="loadData()" v-model="model.tipoAgendamentoIdFiltro" :native-value="tipo.id" >
                                        <b-icon icon="rectangle"  v-if="tipo.cor" :style="tipoAgendamentoStyle(tipo)"></b-icon>
                                        <span class="text-nowrap">{{tipo.nome}}</span>
                                    </b-radio-button>
                                </b-field>
                            </div>
                        </template>
                    </div>
                    <b-tabs position="is-centered" type="is-boxed" v-model="activeTab">
                        <b-tab-item v-if="!config.ocultarCalendarioMensal">
                            <template slot="header">
                                <span> {{$t('AGENDA.MENSAL')}} </span>
                            </template>
                                 
                            <div class="columns" v-show="!isLoading">                                                                 
                                <calendar  is-expanded :locale="{ id: 'pt-BR', firstDayOfWeek: 1, masks: { weekdays: 'WWWW' } }" ref="calendar" @update:from-page="mesAnoAlterado" :from-page="{ month: this.mes, year: this.ano }" >
                                    <template slot="day-content" slot-scope="slot">                                        
                                        <div class="day-content " :class="{[slot.day.classes.join(' ')]: true, 'hoje': slot.day.isToday }" >
                                            <span :tabindex="slot.dayProps.tabIndex" :aria-label="slot.dayProps['aria-label']" class="is-pulled-left vc-day-content vc-focusable vc-font-medium vc-text-sm vc-cursor-pointer focus:vc-font-bold vc-rounded-full">{{slot.day.label}}</span>
                                            <div class="events is-clipped">
                                                <div class="event tile is-child notification" v-if="getEventos(slot.day).length" >
                                                    <agenda-item :eventos="getEventos(slot.day)" :tipoVisao.sync="tipoVisao" v-on:loadData="loadData()" @abrirImprimir="abrirImprimir" @incluirNovoHorarioDia="incluirNovoHorarioDia" ></agenda-item>
                                                </div>
                                                <div class="botoes" v-if="habilitaAdicionar(slot) && !habilitarVisaoDiario()">
                                                    <b-button icon-left="plus" class="icone-adicionar" :title="$t('AGENDA.ADICIONAREVENTO')" :class="{'icone-adicionar-hoje': isHoje(slot.day)}" tag="router-link" :to="{ name: 'agendamento',params: {permiteAlterar:true, tipoAgendamentoId: model.tipoAgendamentoIdFiltro} }" />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </calendar>
                            </div>
                        </b-tab-item>
                        <b-tab-item  v-if="habilitarVisaoDiario()">
                            <template slot="header">
                                <span id="aba_diario">  {{$t('AGENDA.DIARIO')}}  </span>
                            </template>
                            <div class="columns is-multiline whitebox" v-show="!isLoading"> 

                                <div  class="column is-12" >                                     
                                    <b-button v-if="isInRole('agenda-incluir-encaixe')" class="is-pulled-right  ml-2 " type="is-info" icon-left="arrow-collapse-vertical" tag="router-link" :to="{ name: 'agendamento',params: {permiteAlterar:true, tipoAgendamentoId: model.tipoAgendamentoIdFiltro, encaixe:true} }">Encaixe</b-button>
                                    <b-button v-if="isInRole('agenda-desbloquear-horario')" class="is-pulled-right ml-2" @click="confirmarDesbloqueioHorarios" type="is-success" icon-left="cancel">Desbloqueio de Horários</b-button>
                                    <b-button v-if="isInRole('agenda-bloquear-horario')"  class="is-pulled-right"  @click="confirmarBloqueioHorarios" type="is-black"  icon-left="cancel"  >Bloqueio de Horários</b-button>

                                </div>                                
                                                  
                                <div class="column is-12 has-text-centered whitebox" > 

                                        <b-button class="is-pulled-left"  icon-left="chevron-left" @click="addDays(-1);" ></b-button>                                    
                                        <span class="is-size-3"><b>{{$moment(dataDiaFiltro).format('DD/MM/YYYY')}} - {{ getDayOfWeek(dataDiaFiltro)}}</b></span>                                     
                                        <b-button class="is-pulled-right" icon-left="chevron-right"  @click="addDays(1);" ></b-button> 

                                </div>                                 
                                
                                <agenda-item  v-if="eventosDia.length>0" :eventos="eventosDia" :tipoVisao.sync="tipoVisao" v-on:loadData="loadData()" @abrirImprimir="abrirImprimir" @incluirNovoHorarioDia="incluirNovoHorarioDia" ></agenda-item>                                     
                                <div v-else class="notification column is-12 is-warning">
                                    <h4>{{$t('AGENDA.ESCALASNAOCADASTRADAS')}}</h4>                                    
                                </div>                                                              
                      
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </article>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";

    .day-content {
        min-height: 10rem;
        border: solid 1px rgba(203, 213, 224,0.5);
        border-radius: 4px;
        box-sizing: border-box;
        max-height: 10rem;
        overflow-y: auto;
    }


    .text-nowrap{
        text-overflow: ellipsis;
        white-space: nowrap;        
        overflow: hidden;                
    }    

    .weekday-1, .weekday-7 {
        background-color: rgba(203, 213, 224,0.5);
    }

    .hoje {
        background-color: rgba($cyan,0.5);
    }

    .events {        
        margin-top: 2.3rem;
        .botoes {
            position: absolute;
            right:  20px;
            top: 0;
        }
        .event {
            margin: 0;
            padding: 0.1rem;
        }
    }

    .icone-adicionar {
        background-color: rgba($cyan,0.2);
        color: #000;
    }

  
</style>
<script>
    import {  mapState,mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue';    
    import modalImpressaoAgendamento from '@/components/atendimento/modalImpressaoAgendamento.vue';    
    import modalBloqueioHorarios from '@/components/atendimento/modalBloqueioHorarios'
    import modalDesbloqueioHorarios from '@/components/atendimento/modalDesbloqueioHorarios';
    import Calendar from 'v-calendar/lib/components/calendar.umd';
    import moment from 'moment';    
    import agendaItem from '@/components/atendimento/agenda-item.vue';
    import debounce from 'lodash/debounce'
 

    export default {
        components: {
            titlebar,
            Calendar,            
            modalImpressaoAgendamento,
            agendaItem                        
        },

        computed: {

            ...mapState([                
                'config',
                'unidadeId',
                'componentes'
            ]),  
            ...mapGetters([
                'isInRole'
            ]) ,                    
            titleStack() {
                return [
                    this.$t('AGENDA.ATENDIMENTO'),
                    this.$t('AGENDA.AGENDA'),
                ]
            }
        },
        watch: {
            
            unidadeId: function(novaUnidade) {                 
                this.$route.query.unidadeId = novaUnidade;                
                this.$forceUpdate();
                this.loadTiposAgendamentos();
                this.loadData();                                                
            },
            activeTab: function(novoValor) {                        

              if(novoValor==0 ){
                this.mudarVisao('mes');
              }else{
                this.mudarVisao('dia');
              }              
            },
            equipamentosSelecionados: function() {                  
                if( this.config.agendaExibeSeletorEquipamento ){
                    if(this.equipamentosSelecionados.length > 0){
                        this.agendasImagem = false;
                        this.agendasLaboratorial = false;
                        this.loadTiposAgendamentos();                                            
                    }else{                                            
                        this.model.tiposAgendamentoAtivos = [];                                                                                                    
                    }
                    this.procurarEquipamentoTexto = null;
                }        
                                
            },                          
        },        
        data() {
            return {                                 
                model: {
                    tipoAgendamentoIdFiltro:0,
                    tiposAgendamentoAtivos: []
                },                
                eventos: [],
                eventosDia: [],
                ano: null,
                mes: null,
                isLoading: false,                              
                isModalImpressao: false,                
                imprimir: { id: null },
                novo: {
                    data: null,
                    observacao: null,
                    idPaciente: null,
                    idTipoAgendamento: null,
                    funcionarioId: null,
                    paciente: {},
                    tipoAgendamento: {}
                },             
                dataDiaFiltro: new Date(new Date().setHours(0,0,0,0)),
                horariosDia: [], 
                activeTab:0,               
                tipoVisao:'mes', 
                

                agendasLaboratorial: false,
                agendasImagem: false,                
                equipamentosSelecionados: [],                
                procurarEquipamentoTexto: '',                
                incluindoEquipamentoIndex: 0,                       
                listaEquipamentos: null,                      
            }
        },
        created() {
            try {
                this.isLoading = true;
                if (this.$route.query.ano) this.ano = parseInt(this.$route.query.ano);
                if (this.$route.query.mes) this.mes = parseInt(this.$route.query.mes);

                if (this.$route.query.dia) {
                    this.dataDiaFiltro = new Date(0)
                    this.dataDiaFiltro.setDate(this.$route.query.dia)
                    this.dataDiaFiltro.setMonth(this.mes - 1)
                    this.dataDiaFiltro.setFullYear(this.ano)
                    this.dataDiaFiltro.setHours(0,0,0,0)
                }         

                if (this.$route.query.tipoAgendamentoId)
                    this.model.tipoAgendamentoIdFiltro = this.$route.query.tipoAgendamentoId;
                                                
            } catch (ex) {
                console.error(ex);
            }
        },
        mounted() {        
            this.componentes.sidebarIsOpened = false;
            this.loadTiposAgendamentos();
            this.loadData();     
            if(this.config.ocultarCalendarioMensal)
                this.tipoVisao = 'dia';
            
                           
                setTimeout(() => { if(this.config.agendaExibeSeletorEquipamento) {this.model.tiposAgendamentoAtivos = [];} },500);   
        },
        beforeDestroy() {
            this.componentes.sidebarIsOpened = true;
        },        
        methods: {

        inputListaEquipamentos: debounce(function () {
            this.loadListaEquipamentos()
        }, 500), 
        
        async loadListaEquipamentos() {            
                const params = {
                    texto: this.procurarEquipamentoTexto,
                    dicom:true                    
                }
                this.$http.get('/api/search/Equipamentos', { params })
                    .then(res => {
                        this.listaEquipamentos = res.data
                        this.incluindoEquipamentoIndex = 0
                        this.$refs.procurarEquipamentoTextoInput.focus()
                    });

                    
        }, 
        
        async loadTiposAgendamentos(){
            const params = [];
                                                            
            this.isLoading = true;
            try {
                if((this.agendasLaboratorial || this.agendasImagem ) || (this.equipamentosSelecionados && this.equipamentosSelecionados.length > 0)) {
                    this.$http.get('/api/agenda/TiposAgendamentoEquipamento{?unidadeId,equipamentosIds*}', {
                        params: {              
                            unidadeId: this.$route.query.unidadeId,                                      
                            equipamentosIds: this.equipamentosSelecionados.map(x => x.id),
                            agendasLaboratorial: this.agendasLaboratorial,
                            agendasImagem: this.agendasImagem
                        }
                    })
                    .then(res => res.data)
                    .then(data => {                              
                        this.model.tiposAgendamentoAtivos = data;
                    }).catch(e => {
                        throw e;
                    }).finally(() => {  

                        this.$forceUpdate(); 
                        this.isLoading = false;
                    });
                } else {
                    this.isLoading = false;
                }
            } catch (e) {
                console.error(e);
                this.isLoading = false;
            }

        },           
        
        incluirEquipamento(equip) {
            
            this.equipamentosSelecionados.push({                    
                id: equip.id,
                nome: equip.versionNome
            });                    
            
            this.$refs.procurarEquipamentoTextoInput.focus();
        },        

       atualizaUrl() {
                const url = new URL(window.location)

                if (this.tipoVisao == 'dia') {
                    url.searchParams.set('dia', this.dataDiaFiltro.getDate())
                    url.searchParams.set('mes', this.dataDiaFiltro.getMonth() + 1)
                    url.searchParams.set('ano', this.dataDiaFiltro.getFullYear())
                } else {
                    url.searchParams.set('mes', this.mes)
                    url.searchParams.set('ano', this.ano)
                }
                if (this.model.tipoAgendamentoIdFiltro)
                    url.searchParams.set('tipoAgendamentoId', this.model.tipoAgendamentoIdFiltro)

                    
                history.pushState(
                    {},
                    null,
                    url
                )
            },
            habilitarVisaoDiario(){
         

                if(!this.model.tipoAgendamentoIdFiltro || this.model.tipoAgendamentoIdFiltro==0 ){                                    
                    return false;
                }
                        
                return (this.model.possuiEscalasCadatradas || this.config.ocultarCalendarioMensal);
                                        
            },
            habilitarAdicionarDiario(dataDiaFiltro) {
                
                const hoje = new Date();

                hoje.setHours(0, 0, 0, 0);
                dataDiaFiltro.setHours(0, 0, 0, 0);


                return (dataDiaFiltro.getTime() >= hoje.getTime());                
            },                          
            addDays(dia){
                this.dataDiaFiltro.setDate(this.dataDiaFiltro.getDate() + dia);                                
                this.loadData();                                
            },                        
            mudarVisao(visao){    
                this.tipoVisao = visao;                                                                     
                this.loadData()
            },
            getDayOfWeek(dataDiaFiltro) {
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
                return day[this.$moment(dataDiaFiltro).toDate().getDay()];
            },   

            incluirNovoHorarioDia(evento, horario){
                

                const params = {};

                if (evento.tipoAgendamentoId != null) 
                    params.tipoAgendamentoId= evento.tipoAgendamentoId;

                if (horario != null) 
                    params.horario=  moment(horario).toDate(); 

                   params.permiteAlterar = !this.habilitarVisaoDiario();    

                //Editar Paciente
                if(evento.id && evento.id>0){
                    params.id = evento.id;
                    
                    this.$router.push({
                        name: 'agendamento', params: params
                    })
                    
                //Novo paciente
                }else{                                    
                    this.$router.push({
                        name: 'agendamento', params: params
                    })  

                }
              
            },
 
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    day: moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: moment(d.dataHora).format('HH:mm'),
                    data: moment(d.dataHora)
                }
            },
            async loadData() {

                if(this.tipoVisao=='dia'){
                    this.loadDataDia();                    
                }else{                

                    if(!this.config.ocultarCalendarioMensal){
                        const params = [];

                        if (this.ano != null) 
                            params.push(`ano=${this.ano}`)
                        if (this.mes != null) 
                            params.push(`mes=${this.mes}`)
                        if (this.model.tipoAgendamentoIdFiltro) 
                            params.push(`tipoAgendamentoId=${this.model.tipoAgendamentoIdFiltro}`)
                        params.push(`unidadeId=${this.unidadeId}`)
                        this.isLoading = true;

                        try {
                            this.$http.get(`/api/agenda/agenda?${params.join('&')}`)
                                .then(res => res.data)
                                .then(data => {
                                    if (data != null) {
                                        data.tiposAgendamentoAtivos = this.model.tiposAgendamentoAtivos;
                                        this.model = data;
                                        this.eventos = data?.lista
                                            ?.filter(d => d.id > 0)
                                            .map(d => {
                                                return this.getEvento(d);
                                            });
                                        this.atualizaUrl()                                        
                                    }
                                                                                                    
                                                                
                                }).catch(e => {
                                    throw e;
                                }).finally(() => {

                                    if(this.config.ocultarCalendarioMensal){                                                                                                                 
                                        setTimeout(() => {this.activeTab = 1;   this.ocultarCalendarioMensal = true; },500);                                       
                                    }                                                                
                                    this.$forceUpdate(); 
                                    this.isLoading = false;                                                                                  
                                });                            
                        } catch (e) {
                            console.error(e);
                            this.isLoading = false;
                        }
                    }
                }
            },
            loadDataDia(){
                const params = [];
                
                if (this.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(this.dataDiaFiltro.setHours(0,0,0,0)).format('YYYY-MM-DDTHH:mm:ss')}`);                                     
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if(this.model.tipoAgendamentoIdFiltro) params.push(`tipoAgendamentoId=${this.model.tipoAgendamentoIdFiltro}`);
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/AgendaDia?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.eventosDia = data?.lista                                    
                                    .map(d => {
                                        return this.getEvento(d);
                                    });
                                this.atualizaUrl()
                                   
                            }                            
                                                                                                                
                        }).catch(e => {
                            throw e;
                        }).finally(() => {

                            if(this.config.ocultarCalendarioMensal){                                                                                                                 
                                setTimeout(() => {document.querySelector("[role=tab]").firstElementChild.click(); },500);   
                            }                                                                
                            this.$forceUpdate(); 
                            this.isLoading = false;                                                      
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }                
                
            },            
            habilitaAdicionar(slot) {
                if (this.isHoje(slot.day)) return true;

                return moment(slot.day.id).isSameOrAfter(moment());
            },
            isHoje(day) {
                return day.id === (moment().format('YYYY-MM-DD'));
            },
            getEventos(day) {                
                return this.eventos?.filter(e => { return day.id === e.day });                
            },

            async adicionarAgendamento(evento) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);
                params.push(`pacienteId=${evento.paciente.id}`);
                params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`);
                if(evento.funcionarioId == null) evento.funcionarioId = 0;
                params.push(`funcionarioId=${evento.funcionarioId}`);
                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);
                params.push(`observacao=${evento.observacao ? evento.observacao : ''}`);
                if (this.$route.query.unidadeId != null)
                    params.push(`unidadeId=${this.$route.query.unidadeId}`)
                else
                    params.push('unidadeid=');

                try {
                    let url = `/api/agenda/agendaincluir?${params.join('&')}`;

                    if (evento.id) {
                        url = `/api/agenda/agendaEditar?${params.join('&')}`;
                    }

                    this.isLoading = true;
                    const res = await this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                        if (data.mensagem != null && data.mensagem != "") {
                           this.$buefy.toast.open({
                            duration: 5000,
                            message: data.mensagem,
                            type: 'is-danger',
                            queue: false
                        })
                        }                                    
                    })                     
                    this.loadData();
                } catch (e) {                    
                    console.error(e);
                    this.isLoading = false;
                }

            },            
            mesAnoAlterado(event) {                
                if (!this.isLoading && (event.month != this.mes || event.year != this.ano)) {                    
                    this.mes = event.month;
                    this.ano = event.year;                    
                    this.loadData();
                }
            },
            selecionarAcao(item, data, dia) {                
                item.click(data, dia);
            },
            abrirImprimir(item) {                
                this.imprimir = { id: null }
                this.isModalImpressao = true;
                this.imprimir.id = item.id;
            },
            imprimirModelo(data) {
                const form = document.createElement("form");
                const inputId = document.createElement("input");
                inputId.name = 'id';
                const inputModelo = document.createElement("input");
                inputModelo.name = 'modelo';

                form.method = "POST";
                form.action = "/api/agenda/Agendamento";

                inputId.value = this.imprimir.id;
                inputModelo.value = data.nome;

                form.appendChild(inputId);
                form.appendChild(inputModelo);

                data.variaveis?.forEach(v => {
                    const input = document.createElement("input");
                    input.name = v.nome;
                    input.value = v.valor;
                    form.appendChild(input);
                });


                document.body.appendChild(form);
                form.setAttribute('target','_blank');
                form.submit();
            },
            tipoAgendamentoStyle(tipo) {
                return 'color: #' + tipo.cor + '!important'
            },
            confirmarBloqueioHorarios() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalBloqueioHorarios,
                    props: {
                        tipoAgendamento: this.model.tiposAgendamentoAtivos.filter( t => t.id == this.model.tipoAgendamentoIdFiltro)[0],
                        unidadeId: this.unidadeId
                    },
                    hasModalCard: true,
                    events: {
                        loadData: this.loadData
                    },
                    trapFocus: true,
                    fullScreen: true
                })
            },      
            confirmarDesbloqueioHorarios() {
                this.$buefy.modal.open({
                parent: this,
                component: modalDesbloqueioHorarios, 
                props: {
                    tipoAgendamento: this.model.tiposAgendamentoAtivos.filter(t => t.id == this.model.tipoAgendamentoIdFiltro)[0],
                    unidadeId: this.unidadeId,
                },
                hasModalCard: true,
                events: {
                    loadData: this.loadData,
                },
                trapFocus: true,
                fullScreen: true,
                });
            },              
        }
    }
</script>