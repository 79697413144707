<template>
    <section>
        <titlebar :title-stack="titleStack" v-if="!this.portal"></titlebar>     
        <div id="otimizar_imagem" style="display: none;"></div>
        <div class='box' v-if="lista.length > 0 && !pesquisaColeta">
            <div class="level">
                <div class="level-item">
                    <router-link v-if="this.portal" :to="{ name: 'portalconvenioguia', params: { id: lista[0].guia.id }}" class="button is-primary">
                        {{ lista[0].guia.id }}
                    </router-link>
                    <router-link v-else :to="{ name: 'guia', params: { id: lista[0].guia.id, agendamentoDashboard: agendamentoDashboard }}" class="button is-primary">
                        {{ lista[0].guia.id }}
                    </router-link>
                </div>
                <div class="level-item">
                    <small>Paciente:</small>
                    <b>{{ lista[0].guia.pacienteNome }}</b>
                </div>
                <div class="level-item">
                    <small>Data de retorno:</small>
                    <b>{{ $moment(lista[0].guia.dataDeRetorno).format("DD/MM/YYYY HH:mm") }}</b>
                </div>
            </div>
        </div>

        <div class='box' v-if="lista.length > 0">
            <div class="panel" v-for="(item, index) in lista" v-bind:key="index">
                <div v-if="pesquisaColeta" class="panel-heading">
                    <div class="level">
                        <div class="level-left">
                            <small>Guia:  <b>{{ item.guia.id }}</b></small>
                        </div>
                        <div class="level-left">
                            <small>Paciente:  <b>{{ item.guia.pacienteNome }}</b></small>
                        </div>
                        <div class="level-right">
                            <small>Data de retorno: <b>{{ $moment(item.guia.dataDeRetorno).format("DD/MM/YYYY HH:mm") }}</b></small>
                        </div>
                    </div>
                </div>
                <div class="panel-heading background-2">
                    <div class="level">
                        <div class="level-left">
                            <strong>{{item.pesquisa.nome}}</strong>
                        </div>
                        <div class="level-right">
                            <b-tag v-for="(guiaExameId, j) in item.guiaExameIds" v-bind:key="j" type="is-success">{{item.guia.exames.filter(x => x.item == guiaExameId.item)[0].exameApelido}}</b-tag>
                        </div>
                    </div>
                </div>
                <div class="panel-block columns is-multiline is-justified-between">
                    <div class="column is-12 is-fullwidth" v-for="(pergunta, idxperg) in item.pesquisa.perguntas" :key="idxperg">
                        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" >
                            <tr v-for="(perguntaItem, idxpergitem) in pergunta.perguntaItens" :key="idxpergitem">
                                <template v-if="pergunta.perguntaItens.length <= 10">
                                    <th width="40%">
                                        <span v-if="idxpergitem == 0">{{ pergunta.texto }}</span>
                                    </th>
                                    <td v-if="perguntaItem.aceitaDescritivo">
                                        <b-input type="text" v-model="perguntaItem.resposta"></b-input>
                                    </td>
                                    <td v-else-if="perguntaItem.aceitaData">
                                        <b-datepicker editable v-model="perguntaItem.resposta"></b-datepicker>
                                    </td>
                                    <td v-else-if="perguntaItem.aceitaArquivo">
                                        <div class="columns">
                                            <b-field class="file">
                                                <b-upload @input="incluirAnexo" v-model="arquivoInserido[pergunta.id]" accept=".jpg,.jpeg,.png,.bmp,.pdf" expanded>
                                                    <a class="button is-primary is-fullwidth">
                                                    <b-icon icon="upload"></b-icon>
                                                    <span>{{ (arquivoInserido[pergunta.id]) ? arquivoInserido[pergunta.id].name : "Carregar Arquivo" }}</span>
                                                    </a>
                                                </b-upload>
                                            </b-field>

                                        </div>
                                    </td>
                                    <td v-else-if="perguntaItem.somenteNumerico">
                                        <b-input type="number" v-model="perguntaItem.resposta"></b-input>
                                    </td>
                                    <td v-else-if="perguntaItem.somenteInteiro">
                                        <b-input 
                                            type="text" 
                                            step="1"
                                            v-model="perguntaItem.resposta"
                                            @input="handleInput(index,idxperg, idxpergitem)"
                                        ></b-input>
                                    </td>
                                    <td v-else>
                                            <b-radio v-model="pergunta.resposta"
                                            :name="'lista_' + index + 'pergunta_' + pergunta.id"
                                            :native-value="perguntaItem.id">
                                            {{ perguntaItem.texto }}
                                        </b-radio>
                                    </td>
                                </template>
                            </tr>
                            <tr v-if="pergunta.perguntaItens.length > 10">
                                <th width="40%">
                                    <span>{{ pergunta.texto }}</span>
                                </th>
                                <td>
                                    <b-select v-model="pergunta.resposta" :name="'lista_' + index + 'pergunta_' + pergunta.id">
                                        <option v-for="item in pergunta.perguntaItens" :key="item.id" :value="item.id">{{ item.texto }}</option>
                                    </b-select>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                native-type="button"
                                :loading="salvandoDados"
                                @click="salvar()"
                                icon-left="check-circle">
                        Salvar
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Voltar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import utilFile from '../../assets/js/views/atendimento/utilFile.js'    
    
    export default {
        data() {
            return {
                salvandoDados: false,
                id: this.$route.params.id,
                pagamentoGuia: this.$route.params.pagamentoGuia,
                condicaoPagamento: this.$route.params.condicaoPagamento,
                convenioCoparticipacao: this.$route.params.convenioCoparticipacao,
                pesquisaPosGuia : this.$route.params.pesquisaPosGuia,
                pesquisaExecucao: this.$route.params.pesquisaExecucao,
                agendamentoDashboard: this.$route.params.agendamentoDashboard,
                lista: [],
                formData: new FormData(),
                arquivoInserido: [],
                pesquisaColeta: this.$route.params.pesquisaColeta ?? false,
                funcionarioId: this.$route.params.funcionarioId ?? false,
                guias: this.$route.params.guias,
                amostraColeta: this.$route.params.amostraColeta ?? false,
            }
        },
        props: {
            portal: Boolean
        },
        components: {
            titlebar        
        },
        computed: {
            ...mapState([
                'config'
            ]),            
            titleStack() {
                return [
                    this.portal ? 'Portal' : 'Atendimento',
                    'Pesquisa Clínica', 
                    this.id ? this.id : 'Exames Coleta'
                ]
            }
        },
        mounted() {
                this.salvandoDados = true;

                if(this.$route.params.lista) {
                    this.lista = this.$route.params.lista;
                    this.salvandoDados = false

                }
                else {
                    const params = [`id=${this.$route.params.id}`];

                    if(this.amostraColeta)
                        params.push('amostraColeta=' + this.amostraColeta );
    
                    if(this.pesquisaPosGuia)
                        params.push('pesquisaPosGuia=' + this.pesquisaPosGuia );
    
                    if(this.pesquisaExecucao)
                        params.push('pesquisaExecucao=' + this.pesquisaExecucao);
    
                    this.$http.get(`/api/atendimento/PesquisaClinica?${params.join('&')}`)                
                        .then(({ data }) => {
                            this.lista = data;
                        })
                        .catch((error) => {
                            this.lista = []
                            throw error
                        })
                        .finally(() => {
                            this.salvandoDados = false
                        })
                }
        },
        methods:{


            incluirAnexo(file) {
                                
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Arquivo '+ file.name + ' carregado com sucesso!',
                    type: 'is-success'
                })
                    

            },     
           
            async salvar() {
                let arquivos = '';
                let radioObrigatorio = false;
                const arquivosOtimizados = [];
                this.formData = new FormData(); 
                
                this.arquivoInserido.forEach((arq, index) => {
                    if(arq != null && arq != '' && arq != undefined){                        
                        arquivosOtimizados.push({index: index, arquivo: arq });
                    }
                });
                

                for(let i = 0; i < arquivosOtimizados.length;i++){
                    
                    //Verifica se o arquivo é uma imagem
                    if(utilFile.isFileImage(arquivosOtimizados[i].arquivo)){
                        
                        //Faz a leitura do arquivo e retorna um Blob
                        const newBlob = await utilFile.createFileReader(arquivosOtimizados[i].arquivo); 
                        //Recebe um novo Blob com uma imagem otimizada
                        const newFile = await utilFile.resizeImage(newBlob,arquivosOtimizados[i].arquivo.type,arquivosOtimizados[i].arquivo.name,parseFloat(this.config.percentualOtimizacaoImagem)); 
                        this.formData.append('files', newFile, newFile.name);
                    
                    //Caso contrário segue fluxo normal para salvar o arquivo
                    }else{
                        this.formData.append('files', arquivosOtimizados[i].arquivo, arquivosOtimizados[i].arquivo.name);
                    }
                    arquivos += ',' + arquivosOtimizados[i].index;                    
                    
                }

                if(this.pesquisaColeta != null && this.pesquisaColeta) {
                    this.formData.append("pesquisaColeta", this.pesquisaColeta);
                    this.formData.append("funcionarioId", this.funcionarioId);
                    
                    this.guias.forEach((item, index) => {
                        this.formData.append("guias[" + index + "].Entrega", item.entrega);
                        this.formData.append("guias[" + index + "].ExameApelido", item.exameApelido);
                        this.formData.append("guias[" + index + "].GuiaExameItem", item.guiaExameItem);
                        this.formData.append("guias[" + index + "].GuiaId", item.guiaId);

                    })

                }
                this.formData.append('arquivosAlterados', arquivos.substring(1))

                //TODO: deixar dessa forma para não precisar refazer o método de salvar no back-end, por enquanto
                this.lista.forEach((item, index) => {
                    let guiaExameIds = ''
                    item.guiaExameIds.forEach(guiaExameId => {
                        guiaExameIds += ',' + guiaExameId.guiaId + "_" + guiaExameId.item
                    })

                    item.pesquisa.perguntas.forEach((pergunta, i) => {
                        pergunta.perguntaItens.forEach((perguntaItem, j) => {
                                                                            

                            if(perguntaItem.aceitaData){
                                if(perguntaItem.resposta == null || perguntaItem.resposta==""){
                                    perguntaItem.resposta = null;
                                }else{
                                    perguntaItem.resposta = this.$moment(perguntaItem.resposta).format();
                                }
                            }

                            if(!perguntaItem.somenteNumerico && !perguntaItem.aceitaDescritivo 
                            && !perguntaItem.aceitaArquivo && !perguntaItem.aceitaData && !perguntaItem.somenteInteiro)
                            {
                               if(pergunta.resposta == null || pergunta.resposta == 0) {
                                    radioObrigatorio = true;                         
                               } 
                            }
                            
                            this.formData.append("lista[" + index + "].Pesquisa.Id", item.pesquisa.id);
                            this.formData.append("lista[" + index + "].Pesquisa.Nome", item.pesquisa.nome);
                            this.formData.append("lista[" + index + "].Pesquisa.DestinoPesquisa", item.pesquisa.destinoPesquisa);
                            this.formData.append("lista[" + index + "].Pesquisa.Obrigatorio", item.pesquisa.obrigatorio);
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].GuiaExameIds", guiaExameIds.substring(1));
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].Pergunta.Id", pergunta.id);
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.AceitaData", perguntaItem.aceitaData);
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.AceitaDescritivo", perguntaItem.aceitaDescritivo);
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.AceitaArquivo", perguntaItem.aceitaArquivo);
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.SomenteNumerico", perguntaItem.somenteNumerico);
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.SomenteInteiro", perguntaItem.somenteInteiro);

                            if(perguntaItem.resposta != null && perguntaItem.resposta != '' && perguntaItem.resposta != undefined){
                                this.formData.append("lista[" + index + "].Respostas["+ i +"].Descritivo", perguntaItem.resposta);
                            }else{
                                this.formData.append("lista[" + index + "].Respostas["+ i +"].Descritivo", '');
                            }
                            if(!perguntaItem.aceitaDescritivo && !perguntaItem.aceitaData && !perguntaItem.aceitaArquivo && !perguntaItem.somenteNumerico && !perguntaItem.somenteInteiro){
                                this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.Id",((pergunta.resposta >0) ? pergunta.resposta : 0) );
                            }
                            else {
                                this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.Id", perguntaItem.id);
                            }
                        })
                    })
                });

                if(radioObrigatorio) {
                     this.$buefy.dialog.alert({
                        title: 'Obrigatório!',
                        message: "Todos os campos de multipla escolha são obrigatórios",
                        type: 'is-warning',
                        hasIcon: true,
                        onConfirm: () => {    
                            this.salvandoDados = false   
                        }                                                                                  
                    });

                    return false;
                }  

                this.salvandoDados = true;
                                
                this.$http.post('/api/atendimento/PesquisaClinicaRespostas', this.formData)
                    .then(res => {
                        if(this.pesquisaExecucao) {
                            this.$router.back();

                            return;
                        }

                        if(res.data.mensagem !=null){

                            this.$buefy.dialog.alert({
                                title: 'Obrigatório!',
                                message: res.data.mensagem ,
                                type: 'is-warning',
                                hasIcon: true,
                                onConfirm: () => {                                            
                                    return false;                                            
                                }                                                                                  
                            });

                            this.salvandoDados = false;
                            return false;      

                        }

                    
                        if (res.data.id && res.data.worklist) {
                            
                            this.$router.push({ name: 'worklist', 
                                params: { 
                                    id: res.data.id,
                                    pagamentoGuia: this.pagamentoGuia, 
                                    condicaoPagamento: this.condicaoPagamento,
                                    convenioCoparticipacao:  this.convenioCoparticipacao,
                                    agendamentoDashboard: this.agendamentoDashboard
                                }
                            }); 
                            return;
                        }      
                        
                        if(this.portal){
                            this.$router.push({ name: 'portalconvenioworkflow', 
                                params: { 
                                    id: this.id
                                }
                            });
                        }
                        else {
                            //verifica se possui mais que uma guia.
                            const hasMoreThanOneGuia = (guias) => {
                            let guiaId = null;
                            let guiaCounter = 0;
                                guias.forEach(g => {
                                    if (g.guiaId != guiaId) {
                                        guiaCounter++;
                                    }
                                    guiaId = g.guiaId;

                                });
                                return guiaCounter > 1;
                            }

                            if(this.pagamentoGuia && this.agendamentoDashboard != "true" && (this.condicaoPagamento  != 0 || this.convenioCoparticipacao)){
                                this.$router.push({ name: 'contas-receber-particular', 
                                    params: { 
                                        guiaId: this.id, 
                                        condicaoPagamento: this.condicaoPagamento,
                                        agendamentoDashboard: this.agendamentoDashboard
                                    }
                                });
                            }
                            else if (this.id) {
                                this.$router.push({ name: 'workflow', 
                                    params: { 
                                        id: this.id,
                                        agendamentoDashboard: this.agendamentoDashboard
                                    }
                                });
                            }
                            else if (!hasMoreThanOneGuia(this.guias) && this.pesquisaColeta) {
                                this.$router.push({ name: 'guia', params: { id: this.guias[0].guiaId } });
                                return;
                            } else {
                                this.$router.push({ name: 'atendimento' });
                            }

                           
                            
                        }
                    })
                    .catch((error) => {
                        this.lista = []
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    })
                    
                    
            },
            handleInput(indexLista, indexPergunta, indexPerguntaItem) {
                const resposta = this.lista[indexLista].pesquisa.perguntas[indexPergunta].perguntaItens[indexPerguntaItem].resposta;

                // Remova vírgulas ou pontos do valor para garantir que seja um número inteiro
                if (resposta != null && (resposta.includes(',') || resposta.includes('.'))) {
                    this.lista[indexLista].pesquisa.perguntas[indexPergunta].perguntaItens[indexPerguntaItem].resposta = resposta.includes(',') 
                        ? resposta.replace(',','') 
                        : resposta.replace('.','');

                    this.$forceUpdate()
                }
            },
        }
    }
</script>